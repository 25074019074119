<template>
    <div class="privacy">
        <p style="font-size:20px;margin-bottom:20px;font-weight:600">用户隐私政策条款</p>
        <p v-for="(item,index) in infoData" :key="index" style="line-height:28px;font-size:14px">
          <span v-html="item.dataValue.replace(/\n/g,'<br/>')"></span>
        </p>
    </div>
</template>

<script>
import { resourcesSearch } from "@/api/service";
export default {
    data(){
        return{
            infoData:[]
        }
    },
    methods:{
        getResourcesSearch(){
            resourcesSearch({
                dictKey:'dict:share:userPrivacyPolicy'
            }).then(res=>{
                if(res.data.code==200){
                    this.infoData = res.data.data.protocolContent.data
                }
                
            })
        }
    },
    mounted(){
        this.getResourcesSearch()
    }
}
</script>

<style lang="less" scoped>
.privacy{
    width: 80%;
    margin:  0 auto;
    padding:40px 0;
}
</style>